<template>
  <div></div>
</template>

<script>
export default {
  name: "Payback",
  data() {
    return {
      orderId: null,
      paymentMethod: null,
      paymentStatus: null,
      isPaySuccess: false,
    };
  },
  methods: {
    jumpPaymentSuccess() {
      this.$router.push({
        path: "/" + this.GLOBAL.currentLanguage + "/payment_result",
        query: {
          status: "success",
        },
      });
    },
    jumpPaymentFail() {
      this.$router.push({
        path: "/" + this.GLOBAL.currentLanguage + "/payment_result",
        query: {
          status: "fail",
        },
      });
    },
  },
  created() {
    this.orderId =
      this.$route.query.id === undefined ? null : this.$route.query.id;
    this.paymentMethod =
      this.$route.query.method === undefined ? null : this.$route.query.method;
    this.paymentStatus =
      this.$route.query.status === undefined ? null : this.$route.query.status;
    if (this.paymentStatus !== "return") {
      this.jumpPaymentFail();
    } else {
      if (this.paymentMethod !== "paypal") {
        this.jumpPaymentSuccess();
      } else {
        this.$axios
          .patch(
            "/v1/order/" + this.paymentMethod + "/" + this.orderId + "/capture"
          )
          .then(() => {
            this.jumpPaymentSuccess();
            // eslint-disable-next-line handle-callback-err
          })
          .catch(() => {
            this.jumpPaymentFail();
          });
      }
    }
  },
};
</script>

<style scoped>
</style>
